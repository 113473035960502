<template>
    <button @click="setLocale(otherLocale.code)">
        <slot name="prepend" />
        <span>{{ activeLocale?.name }}</span>
        <slot name="append" />
    </button>
</template>

<script setup lang="ts">
import type { LocaleObject } from '@nuxtjs/i18n'

const { setLocale, locales, localeProperties: activeLocale } = useI18n()

const localeEntities = locales as ComputedRef<LocaleObject[]>

const otherLocale = computed(() => {
    return localeEntities.value.find((i) => i.code !== activeLocale.value.code)!
})
</script>
